body {
  background-color: #F0F2F5; /* Light background for better contrast */
}

.card {
  background-color: #ffffff; /* White background for cards */
  border-radius: 0.5rem; /* Rounded corners */
}

h1 {
  font-weight: 700; /* Bold title */
}

textarea {
  font-size: 1rem;
  line-height: 1.5;
}
